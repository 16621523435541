<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    overlay-opacity="grey darken-2"
    dark
    floating
    persistent
    width="360"
    src="@/assets/cloud-sky-stock-photography-blue-sunlight-sky.jpg"
  >
    <template v-slot:img="attrs">
      <v-img
        v-bind="attrs"
        gradient="to top, rgba(0, 0, 0, .7), rgba(0, 0, 0, .8)"
      />
    </template>
    <v-container>
      <v-row>
        <v-spacer />
        <v-img
          src="@/assets/radarmeteo-marchio.png"
          width="130"
        />
        <v-spacer />
      </v-row>
    </v-container>

    <v-divider class="mx-3 mb-3" />

    <v-list>
      <v-list-item
        active-class="primary white--text"
        to="/"
      >
        <v-list-item-action>
          <v-icon>mdi-shield-account-outline</v-icon>
        </v-list-item-action>

        <v-list-item-title>
          Admin
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        active-class="primary white--text"
        to="/customers"
      >
        <v-list-item-action>
          <v-icon>mdi-card-account-details-outline</v-icon>
        </v-list-item-action>

        <v-list-item-title>
          Customers
        </v-list-item-title>
      </v-list-item>

      <v-list-group
        :value="true"
        prepend-icon="mdi-alert-octagon-outline"
        append-icon=""
      >
        <template v-slot:activator>
          <v-list-item-title>Policy</v-list-item-title>
        </template>

        <template v-slot:default>
          <div class="ml-5">
            <v-list-item
              active-class="primary white--text"
              to="/api-policy"
            >
              <v-list-item-action>
                <v-icon>mdi-code-json</v-icon>
              </v-list-item-action>

              <v-list-item-title>
                Api
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              active-class="primary white--text"
              to="/app-policy"
            >
              <v-list-item-action>
                <v-icon>mdi-account</v-icon>
              </v-list-item-action>

              <v-list-item-title>
                App
              </v-list-item-title>
            </v-list-item>
          </div>
        </template>
      </v-list-group>

      <v-list-item
        active-class="primary white--text"
        to="/domain"
      >
        <v-list-item-action>
          <v-icon>mdi-image-filter-hdr</v-icon>
        </v-list-item-action>

        <v-list-item-title>
          Domain
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        active-class="primary white--text"
        to="/services"
      >
        <v-list-item-action>
          <v-icon>mdi-application-cog</v-icon>
        </v-list-item-action>

        <v-list-item-title>
          Applications
        </v-list-item-title>
      </v-list-item>

      <v-list-group
        v-if="ticketingArray.length > 0"
        :value="false"
        prepend-icon="mdi-broadcast"
        append-icon=""
      >
        <template v-slot:activator>
          <v-list-item-title>
            Ticketing
          </v-list-item-title>
        </template>
        <v-list-item
          active-class="primary white--text"
          class="ml-5"
          to="/evasion-slot-limits"
        >
          <v-list-item-icon />
          <v-list-item-title class="submenu-size">
            Evasion Slot Limits
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          active-class="primary white--text"
          class="ml-5"
          to="/ticketing-list"
        >
          <v-list-item-icon />
          <v-list-item-title class="submenu-size">
            Complete request list
          </v-list-item-title>
        </v-list-item>
        <div
          v-for="item in ticketingArray"
          :key="item.service_id"
          class="ml-5"
        >
          <v-list-group
            :value="false"
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-title>
                {{ item.service_name }}
              </v-list-item-title>
            </template>
            <v-list-item
              active-class="primary white--text"
              @click="changeTicketingRoute(item, 'request')"
            >
              <v-list-item-title class="submenu-size">
                Insert request for Customer
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              active-class="primary white--text"
              @click="changeTicketingRoute(item, 'request_list')"
            >
              <v-list-item-title class="submenu-size">
                Request list
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list-group>
    </v-list>
    <v-list-item
      active-class="primary white--text"
      to="/layers"
      @click="customer.crm_id = ''"
    >
      <v-list-item-action>
        <v-icon>mdi-layers-triple-outline</v-icon>
      </v-list-item-action>

      <v-list-item-title>
        Global Layers
      </v-list-item-title>
    </v-list-item>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,

      admins: [
        ['Management', 'mdi-account-multiple-outline'],
        ['Settings', 'mdi-cog-outline']
      ]
    }
  },
  data () {
    return {
      ticketingArray: []
    };
  },
  computed: {
    ...mapState(['services', 'drawer', 'layers', 'customer', 'app_user']),
    inputValue: {
      get() {
        return this.drawer;
      },
      set(val) {
        this.setDrawer(val);
      }
    }
  },
  mounted() {
    this.createTicketingArray();
  },
  methods: {
    ...mapMutations(['setDrawer', 'toggleDrawer']),
    ...mapActions(['storeServices', 'storeLinkedCustomers']),
    async changeTicketingRoute(item, request_type) {
      if (request_type == 'request') {
        this.app_user.aUsers = [];
        this.$router.push({
          name: 'TicketingRequest',
          params: { id: item.service_id }
        });
        const data = {
          service_type: 'TICKET',
          service_id: item.service_id
        };
        await this.storeLinkedCustomers(data);
        this.services.linkedCustomers.forEach(c => c.disabled = c.limit_reached);
      } else {
        this.$router.push({
          name: 'TicketingRequestList',
          params: { id: item.service_id }
        });
      }
    },
    async createTicketingArray() {
      this.ticketingArray = [];

      if (this.services.services.length == 0) {
        await this.storeServices();
      }

      this.services.services.forEach(item => {
        if (item.service_state === 'ACTIVE' && item.service_type == 'TICKET') {
          this.ticketingArray.push(item);
        }
      });
      this.ticketingArray.sort(((a, b) => (a.service_name.localeCompare(b.service_name, 'en', { numeric: true }))));
    }
  }
};
</script>

<style scoped>
.serviceItems {
  font-size: 14px;
  margin-left: 70px;
}

.transparent-backgroud-class {
  background-color: transparent;
}
.submenu-size{
  font-size: 13.5px;
}
</style>
